export const DefaultCarouselSettings = {
  dots: true,
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 10 * 1000,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  accessibility: true,
};
